import { css, jsx } from '@emotion/react'

// https://chakra-ui.com/docs/feedback/toast

import { createStandaloneToast } from '@chakra-ui/react'
import { Alert, AlertIcon, AlertTitle, AlertDescription } from './Alert'
import { Box } from './Box'
import { CloseButton } from './CloseButton'
import theme from '../../constants/theme'

const showDefaultTheme = css`
  background: ${theme.colors.primary.base};
  color: ${theme.colors.white};
  i {
    span,
    svg {
      color: ${theme.colors.white};
      fill: ${theme.colors.white};
    }
  }
`

const styles = {
  container: showDefault => css`
    width: auto;
    display: flex;
    align-items: start;
    position: relative;
    overflow: hidden;
    border-radius: 0.375rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    text-align: left;
    ${showDefault && showDefaultTheme};

    max-width: 280px;
    min-width: 240px;
    float: right;
  `,
  closeButton: css`
    width: 24px;
    height: 24px;
    font-size: 10px;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    flex-shrink: 0;
    border-radius: 0.375rem;
  `,

  title: css`
    line-height: 1.1rem;
    margin-bottom: 10px;
    word-break: break-word;
    @media screen and (max-width: 425px) {
      font-size: 15px;
    }
  `,
  description: css`
    line-height: 1.1rem;
    word-break: break-word;
    @media screen and (max-width: 425px) {
      font-size: 15px;
    }
  `,
  iconCustom: css`
    margin-right: 0.75rem;
  `,
}

export const standLoneToast = createStandaloneToast()

export const Toast = ({ status, variant = 'solid', ...rest }) => {
  const showDefault = !(status && status !== 'default')

  return standLoneToast({
    ...rest,
    render: props => (
      <Alert status={status || 'info'} css={styles.container(showDefault)} variant={variant}>
        <i>{rest.icon ? <span css={styles.iconCustom}>{rest.icon}</span> : <AlertIcon />}</i>
        <Box flex="1">
          <AlertTitle css={styles.title}>{rest.title}</AlertTitle>
          <AlertDescription display="block" css={styles.description}>
            {rest.description}
          </AlertDescription>
        </Box>
        <CloseButton css={styles.closeButton} onClick={() => props.onClose()} />
      </Alert>
    ),
  })
}

Toast.close = standLoneToast.close
Toast.closeAll = standLoneToast.closeAll
