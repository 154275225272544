import useSWR from 'swr'
import api from './api'

const getBillingFetcher = url => api.get(url).then(res => res.data)
const object = {}
export function useGetBilling() {
  const { data, error, isLoading } = useSWR('/billing', getBillingFetcher)

  return {
    billing: data || object,
    loadingBilling: isLoading,
    errorBilling: error,
  }
}

export const getPaymentLink = async planId => {
  const response = await api.get(`/billing/payment-link?plan=${planId}`)
  return response.data
}

export const updatePlan = async newPlan => {
  const response = await api.put(`/billing/update?newPlan=${newPlan}`)
  return response.data
}

export const cancelPlan = async () => {
  const response = await api.post('/billing/cancel')
  return response.data
}

export const addCustomLifetime = async payload => {
  const response = await api.post('/billing/add-custom', { ...payload })
  return response.data
}
