import { Folder } from '../components/chakra/icons/Folder'
import { Star } from '../components/chakra/icons/Star'
import { ForbiddenFolder } from '../components/chakra/icons/ForbiddenFolder'
import { AllBookmarks } from '../components/chakra/icons/AllBookmarks'
import { TagsOutline } from '../components/chakra/icons/TagsOutline'
import { Toast } from '../components/chakra/Toast'
import { FolderFilled } from '../components/chakra/icons/FolderFilled'
import { StarFilled } from '../components/chakra/icons/StarFilled'
import { ForbiddenFolderFilled } from '../components/chakra/icons/ForbiddenFolderFilled'
import { AllBookmarksFilled } from '../components/chakra/icons/AllBookmarksFilled'
import { TagsOutlineFilled } from '../components/chakra/icons/TagsOutlineFilled'
import { UsersOutline } from '../components/chakra/icons/UsersOutline'
import { UsersOutlineFilled } from '../components/chakra/icons/UsersOutlineFilled'
import { MonitoredWatchFill } from '../components/chakra/icons/MonitoredWatchFill'
import { MonitoredWatch } from '../components/chakra/icons/MonitoredWatch'


export const breakpointsValues = {
  base: 'base',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

export const siteName =  'BacktoIt.io'

export const iosShortcut = 'https://www.icloud.com/shortcuts/ab919507dce24128b7c8703a74262697'

export const publicSiteUrl = process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : 'https://backtoit.io'

export const twitterAccount = 'backtoit'

export const hiGuardoEmail = 'admin@backtoit.io'

export const twitterGuardo = `https://twitter.com/${twitterAccount}`

export const publicRoadmapUrl =
  'https://www.notion.so/b56dd1631f614229a5618d7775ba4d2f?v=ea94cb805c344528b3d5191514368025'

export const splitJoinTagsString = ',' // Just by tag no space like tag,tag,tag

export const sidebarWidthDefault = 350
export const PublicDefaultIcon = ({ filled, ...props }) =>
  filled ? <UsersOutlineFilled {...props} /> : <UsersOutline {...props} />
export const FolderDefaultIcon = ({ filled, ...props }) =>
  filled ? <FolderFilled {...props} /> : <Folder {...props} />
export const FavouriteDefaultIcon = ({ filled, ...props }) => (filled ? <StarFilled {...props} /> : <Star {...props} />)
export const UncategorizedDefaultIcon = ({ filled, ...props }) =>
  filled ? <ForbiddenFolderFilled {...props} /> : <ForbiddenFolder {...props} />
export const AllBookmarksDefaultIcon = ({ filled, ...props }) =>
  filled ? <AllBookmarksFilled {...props} /> : <AllBookmarks {...props} />
export const AllTagsDefaultIcon = ({ filled, ...props }) =>
  filled ? <TagsOutlineFilled {...props} /> : <TagsOutline {...props} />
  export const MonitoredDefaultIcon = ({ filled, ...props }) => (filled ? <MonitoredWatchFill {...props} /> : <MonitoredWatch {...props} />)


export const toastError = slug => {
  Toast.close(`toast-${slug}`)
  Toast({
    id: `toast-${slug}`,
    title: `Error fetching ${slug}`,
    description: `There was an error fetching yours ${slug}`,
    status: 'error',
    duration: 15000,
    isClosable: true,
    position: 'top-right',
  })
}

const billingsPlans = {
  starter: {
    name: 'Starter',
    id: '111111',
    price: '0',
  },
  premium: {
    name: 'Premium',
    id: '756804',
    price: '15',
  },
  lifetime: {
    name: 'Lifetime',
    id: '756917',
    price: '45',
  },
  premiumYearly: {
    name: 'Premium yearly',
    id: '756804',
    price: '24',
  },
  premiumMonthly: {
    name: 'Premium monthly',
    id: '784880',
    price: '2.50',
  },
}

export const billings = {
  // Same as billingsPlans
  ...billingsPlans,
  // Same as billingsPlans but by ids: { 756917: { ...billingsPlans.lifetime } } an so on
  ...Object.values(billingsPlans).reduce((acc, current) => {
    acc[current.id] = current
    return acc
  }, {}),
}
